export var get_cookie = function (cookieName) {
    if (document.cookie.includes(cookieName)) {
        let eleCookie = document.cookie.split(';').find(c => c.trim().startsWith(cookieName + '='));
        if (eleCookie) {
            let eleCookieValue = eleCookie.split('=')[1];
            return eleCookieValue; // Return the value
        }
    }
    return null; // Return null if the cookie or value is not found
};

export var update_cookie = function (cookieName, value) {
    var expires = (new Date(Date.now() + 31556926000)).toUTCString() //1 year
    document.cookie = cookieName + '=' + value + ";expires=" + expires + ";path=/"
}

export var fromCookieValueCarouselExamples = function () {
    var cookie_name = 'carousel-examples-state'
    if (document.cookie.includes(cookie_name)) {
        var eleCookieValue = get_cookie(cookie_name)
        if (eleCookieValue) {

            var ele = document.getElementById('carouselExamples_desktop');
            var icon = document.getElementById('carouselExamples_desktop-icon');

            if (ele){
                if (eleCookieValue === 'hide') {
                    ele.className = 'carousel slide d-none d-sm-none';
                    icon.className = 'fas fa-angle-down ml-1 align-text-bottom';


                } else {
                    ele.className = 'carousel slide d-none d-sm-block';
                    icon.className = 'fas fa-angle-up ml-1 align-text-bottom'
                }
            }
        }
    }

}


export var fromCookieValueKeywords = function () {
    var cookie_name = 'keywords-state'
    if (document.cookie.includes(cookie_name)) {
        var eleCookieValue = get_cookie(cookie_name)
        if (eleCookieValue) {

            var ele = document.getElementById('keywordsdiv')
            var icon = document.getElementById('keywordsdiv-icon');

            if (eleCookieValue === 'hide') {
                ele.style.display = 'none'

                icon.className = 'fas fa-angle-down ml-1 align-text-bottom'
            } else {
                ele.style.display = 'block'
                icon.className = 'fas fa-angle-up ml-1 align-text-bottom'
            }
        }
    }

    var cookie_name = 'groups-state'
    if (document.cookie.includes(cookie_name)) {
        var eleCookieValue = get_cookie(cookie_name)
        if (eleCookieValue) {

            var ele = document.getElementById('groupsdiv')

            if (eleCookieValue === 'hide') {
                ele.style.display = 'none'

            } else {
                ele.style.display = 'block'
            }
        }
    }

    var cookie_name = 'tags-state'
    if (document.cookie.includes(cookie_name)) {
        var eleCookieValue = get_cookie(cookie_name)
        if (eleCookieValue) {

            var ele = document.getElementById('tagsdiv')

            if (eleCookieValue === 'hide') {
                ele.style.display = 'none'

            } else {
                ele.style.display = 'block'
            }
        }
    }
}

export function toggleKeywords() {
    var cont = document.getElementById('keywordsdiv');
    var icon = document.getElementById('keywordsdiv-icon');

    if (cont.style.display == 'block') {
        cont.style.display = 'none';
        icon.className = 'fas fa-angle-down ml-1 align-text-bottom';
        update_cookie('keywords-state', "hide");
    } else {
        cont.style.display = 'block';
        icon.className = 'fas fa-angle-up ml-1 align-text-bottom';
        update_cookie('keywords-state', "show");
    }
}

export function toggleGroups() {
    var cont = document.getElementById('groupsdiv');

    if (cont.style.display == 'block') {
        cont.style.display = 'none';
        update_cookie('groups-state', "hide");
    } else {
        cont.style.display = 'block';
        update_cookie('groups-state', "show");
    }
}

export function toggleCustomTags() {
    var cont = document.getElementById('tagsdiv');

    if (cont.style.display == 'block') {
        cont.style.display = 'none';
        update_cookie('tags-state', "hide");
    } else {
        cont.style.display = 'block';
        update_cookie('tags-state', "show");
    }
}
